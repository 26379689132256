:root {

    --animation-color: white;
   
   }
   
   .loader {
    /* width: inherit;  */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
   }
   
   
   
   .sub_loader {
   
    display: flex;
   
    justify-content: space-evenly;
   
    width: 38px;

   
   }
   
   .sub_loader1 {
   
    display: flex;
   
    flex-direction: column;
   
    align-items: center;
   
   }
   
   .loaderBottom{
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
   
   }
   
   .loaderMiddleText{ 
    margin-top: 10vh;
   }
   
   .loaderMiddleImg{
    margin-bottom: -80px;
    width: 190px;
   }
   
   .loaderBottomImg{
    width: 50px;
   }
   
   .loaderBottom>p{
    font-size: 10px;
   }
   
   .circle_loader {
    
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    color: var(--animation-color);
    vertical-align: middle;
   
   }
   
   
   
   .circle_loader:nth-child(1) {
    background-color: var(--animation-color);
    transform: translate(-5px);
    opacity: 0;
    animation: animateLoader1 0.5s linear infinite;
   
   }
   
   
   
   .circle_loader:nth-child(2) {
    background-color: var(--animation-color);
    animation: animateLoader-middle 0.5s linear infinite;
   
   }
   
   
   
   .circle_loader:nth-child(3) {
    background-color: var(--animation-color);
    animation: animateLoader-middle 0.5s linear infinite;
   
   }
   
   
   
   .circle_loader:nth-child(4) {
    background-color: var(--animation-color);
    animation: animateLoader2 0.5s linear infinite;
   
   }
   
    @keyframes animateLoader1 {
    to {
     -webkit-transform: translate(5px);
     transform: translate(5px);
     opacity: 1;
   
    }
   
   }
   
    @keyframes animateLoader2 {
    to {
     -webkit-transform: translate(10px);
     transform: translate(10px);
     opacity: 0;
    }

   }
   
   
@keyframes animateLoader-middle {
    to {
     -webkit-transform: translate(5px);
     transform: translate(5px);
     opacity: 1;
   
    }
   } 
   @media only screen and (min-width: 1025px){
   
    .loaderBottom{
     position: fixed;
     bottom: 0;
   
   }
   
   .loader {
    /* margin-top: 10%; */
    width: inherit;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
   }
   
   .loaderMiddleText{
    margin-top: 30px;
   }
   
   }